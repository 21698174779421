const ProductList = {
            "1":{
                "type": "3D",
                "imageURL":"https://solutions-engineering.s3.amazonaws.com/quickdemo/fashion/purse.png",
                "threekit" : "f87b82fb-4425-418d-a675-65a22a79827a",
                "name" :"Handbag",
                "scene": "24c4fce9-3bb8-4b41-b429-17d9a81a4d46",
                "showAR": true,
                "brand": "Personalize the perfect handbag"
            },
            
        };
export {ProductList};